<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <v-form
        ref="form"
        class="multi-col-validation mt-6"
      >
        <v-row>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="dataComputed.name"
              :label="t('fields.name')"
              dense
              outlined
              :readonly="option===2"
              :rules="[required]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model.number="dataComputed.facility_id"
              dense
              outlined
              :label="t('facilities.facility')"
              :items="facilitiesOptions"
              item-text="name"
              item-value="id"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model.number="dataComputed.sport_id"
              dense
              outlined
              :label="t('sports.sport')"
              :items="sportsOptions"
              item-text="name"
              item-value="id"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model.number="dataComputed.players_qty"
              :label="t('fields.players_qty')"
              dense
              outlined
              :readonly="option===2"
              :rules="[required, integerValidator]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model.number="dataComputed.camera_id"
              dense
              outlined
              :label="t('cameras.camera')"
              :items="camerasOptions"
              item-text="name"
              item-value="id"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[]"
              clearable
            ></v-select>
          </v-col>

          <!-- <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="dataComputed.pricing_type"
              dense
              outlined
              class="select"
              :label="t('fields.pricing_type')"
              :items="pricingOptions"
              item-text="text"
              item-value="value"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            ></v-select>
          </v-col> -->

          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model.number="dataComputed.recording_type"
              dense
              outlined
              :label="t('fields.recording_type')"
              :items="recordingOptions"
              item-text="text"
              item-value="value"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-checkbox
              v-model="dataComputed.allow_public_cards"
              :label="t('fields.allow_public_cards')"
              true-value="Y"
              false-value="N"
              color="secondary"
              dense
              hide-details
            ></v-checkbox>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="dataComputed.blocked_fields_ids"
              :label="t('fields.shared_fields')"
              color="secondary"
              :items="fieldsOptions"
              item-text="name"
              item-value="id"
              multiple
              dense
              outlined
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model.number="dataComputed.hourly_pricing"
              :label="t('fields.hourly_pricing')"
              dense
              outlined
              :readonly="option===2"
              prefix="$"
              :rules="[required, regexValidator(dataComputed.hourly_pricing, '^[0-9]{0,9}(\.?[0-9]{0,2})?$', 'Incorrect format')]"
              @input="e => {
                if (!charUnique(e, '.')) {
                  dataComputed.hourly_pricing = null
                }
              }"
              @keypress="onlyNumberDot"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-checkbox
              v-model="dataComputed.premium_video_available"
              :label="t('fields.premium_video_available')"
              true-value="Y"
              false-value="N"
              hide-details
              dense
              color="secondary"
            ></v-checkbox>
          </v-col>

          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              v-model.number="dataComputed.user_hourly_price"
              :label="t('fields.user_hourly_price_usd')"
              dense
              outlined
              :readonly="option===2"
              prefix="$"
              :rules="[required, regexValidator(dataComputed.user_hourly_price, '^[0-9]{0,9}(\.?[0-9]{0,2})?$', 'Incorrect format')]"
              @input="e => {
                if (!charUnique(e, '.')) {
                  dataComputed.user_hourly_price = null
                }
              }"
              @keypress="onlyNumberDot"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              v-model.number="dataComputed.booking_hourly_price"
              :label="t('fields.booking_hourly_price')"
              dense
              outlined
              :readonly="option===2"
              prefix="$"
              :rules="[required, regexValidator(dataComputed.booking_hourly_price, '^[0-9]{0,9}(\.?[0-9]{0,2})?$', 'Incorrect format')]"
              @input="e => {
                if (!charUnique(e, '.')) {
                  dataComputed.booking_hourly_price = null
                }
              }"
              @keypress="onlyNumberDot"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="2"
          >
            <v-select
              v-model="dataComputed.status"
              dense
              outlined
              :label="t('status.status')"
              :items="statusOptions"
              item-text="text"
              item-value="value"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              ref="refCopy"
              v-model="dataComputed.full_url_qr_code"
              :value="dataComputed.full_url_qr_code"
              :label="t('fields.link')"
              dense
              outlined
              readonly
              :append-icon="icons.mdiContentCopy"
              @click:append="copy"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              v-model.number="dataComputed.user_hourly_price_2"
              :label="t('fields.user_hourly_price_eur')"
              dense
              outlined
              :readonly="option===2"
              prefix="€"
              :rules="[regexValidator(dataComputed.user_hourly_price_2, '^[0-9]{0,9}(\.?[0-9]{0,2})?$', 'Incorrect format'), minNumber(dataComputed.user_hourly_price_2, 1)]"
              @input="e => {
                if (!charUnique(e, '.')) {
                  dataComputed.user_hourly_price_2 = null
                }
              }"
              @keypress="onlyNumberDot"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-range-slider
              ref="refRange"
              v-model="range"
              :max="maxRange"
              :min="minRange"
              hide-details
              dense
              class="cam-range align-start"
              color="secondary"
              :label="$t('fields.cam_vertical_range')"
            >
              <template v-slot:prepend>
                <v-text-field
                  :value="range[0]"
                  class="ma-0 pa-0 btn-lateral"
                  hide-details
                  single-line
                  type="number"
                  outlined
                  readonly
                  dense
                  @change="$set(range, 0, $event)"
                ></v-text-field>
              </template>
              <template v-slot:append>
                <v-text-field
                  :value="range[1]"
                  class="ma-0 pa-0 btn-lateral"
                  hide-details
                  single-line
                  type="number"
                  outlined
                  readonly
                  dense
                  @change="$set(range, 1, $event)"
                ></v-text-field>
              </template>
            </v-range-slider>
          </v-col>

          <options-buttom-tab
            :tab-number="tab"
            :is-mode-save-edit="option !== 2"
            :no-save="true"
            :info-required="true"
            :no-cancel="option === 3"
            :no-arrow="option === 2"
            @onCancel="onCancelButton"
            @onNextTab="changeTab"
            @onSave="onSaveAction"
          >
          </options-buttom-tab>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mdiAlertOutline, mdiCloudUploadOutline, mdiContentCopy, mdiMenuDown } from '@mdi/js'
import { ref, computed, watch, onMounted, getCurrentInstance } from '@vue/composition-api'
import { required, integerNoNegativeValidator, regexValidator, charUnique, integerValidator, minNumber } from '@core/utils/validation'
import { onlyNumberDot } from '@core/utils/functionsHelpers'
import { useUtils } from '@core/libs/i18n'
import { getSportsActive, getFacilitiesActive, getCameras, getFieldsAllByFacility } from '@api'
import { copyText } from 'vue3-clipboard'
import { success, error } from '@core/utils/toasted'
import useSelectOptions from '@core/utils/useSelectOptions'
import OptionsButtomTab from '@/views/components/options-buttom-tab/OptionsButtomTab.vue'

export default {
  components: {
    OptionsButtomTab,
  },
  props: {
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
    facilities: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const form = ref(null)
    const { t } = useUtils()
    const { configFacility } = useSelectOptions()
    const vm = getCurrentInstance().proxy
    const refCopy = ref(null)
    const refRange = ref(null)

    const tabComputed = computed(() => props.tab)
    const dataComputed = computed(() => props.dataParams)

    const statusOptions = computed(() => [
      { text: t('status.active'), value: 'A' },
      { text: t('status.inactive'), value: 'I' },
    ])

    const pricingOptions = computed(() => [
      { text: t('status.free'), value: 'F' },
      { text: t('status.paid'), value: 'P' },
    ])

    const minRange = ref(-180)
    const maxRange = ref(180)
    const range = ref([0, 180])
    const sportsOptions = ref([])
    const camerasOptions = ref([])
    const facilitiesOptions = ref([])
    const fieldsOptions = ref([])
    const recordingOptions = computed(() => [
      { text: t('status.basic'), value: 'S' },
      { text: t('status.premium'), value: 'T' },
    ])

    const onCancelButton = () => {
      form.value.reset()
    }

    const changeTab = tab => {
      if (form.value.validate()) {
        const [min, max] = range.value
        dataComputed.value.cam_vertical_range_min = min
        dataComputed.value.cam_vertical_range_max = max
        emit('changeTab', tab, true, dataComputed.value)
      }
    }

    const onSaveAction = () => {
      if (form.value.validate()) {
        const [min, max] = range.value
        dataComputed.value.cam_vertical_range_min = min
        dataComputed.value.cam_vertical_range_max = max
        emit('save', dataComputed.value)
      }
    }

    watch([tabComputed], () => {
      const [min, max] = range.value
      dataComputed.value.cam_vertical_range_min = min
      dataComputed.value.cam_vertical_range_max = max
    })

    onMounted(async () => {
      const responseSports = await getSportsActive()
      sportsOptions.value = responseSports.data

      const responseCameras = await getCameras()
      camerasOptions.value = responseCameras.data

      const responseFacilities = await getFacilitiesActive()
      facilitiesOptions.value = responseFacilities.data

      const responseFields = await getFieldsAllByFacility(configFacility.value || 0)
      fieldsOptions.value = responseFields.data
      range.value = [dataComputed.value.cam_vertical_range_min, dataComputed.value.cam_vertical_range_max]
    })

    const copy = () => {
      copyText(refCopy.value.value, undefined, e => {
        if (e) {
          error(vm.$t('tooltip.not_copy'))
        } else {
          success(vm.$t('tooltip.copied'))
        }
      })
    }

    return {
      form,
      configFacility,
      statusOptions,
      sportsOptions,
      camerasOptions,
      facilitiesOptions,
      fieldsOptions,
      pricingOptions,
      recordingOptions,
      refCopy,
      minRange,
      maxRange,
      range,
      refRange,

      // computes
      dataComputed,

      // methods
      onSaveAction,
      onCancelButton,
      changeTab,
      copy,

      // validations
      required,
      integerNoNegativeValidator,
      regexValidator,
      charUnique,
      onlyNumberDot,
      integerValidator,
      minNumber,

      // i18n
      t,

      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiContentCopy,
        mdiMenuDown,
      },
    }
  },
}
</script>
<style lang="scss">
.btn-lateral {
  max-width: 72px !important;
  min-width: 72px !important;
  max-height: 72px !important;
  min-height: 72px !important;
}

.cam-range >  .v-input__prepend-outer, .v-input__append-outer {
  margin: 0px !important;
}

.cam-range > .v-input__control > .v-input__slot {
  display: flex;
  flex-direction: column;

  label {
    margin: 0px !important;
  }

  .v-slider--horizontal {
    width: 85%;
  }
}
</style>
